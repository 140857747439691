import React from 'react'
import { graphql } from 'gatsby'
import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'

import { SEO, Layout } from 'Components'

import './press.css'

dayjs.extend(localizedFormat)

export const EducationQuery = graphql`
  query {
    press: allContentfulPress(sort: { fields: [featured, publishDate], order: DESC }) {
      totalCount
      edges {
        node {
          id
          title
          description
          publishDate
          url
          featured
        }
      }
    }
  }
`

const Press = ({ location, data }) => {
  const { press } = data
  const { edges } = press

  return (
    <>
      <SEO pathname={location.pathname} title="Press" description="Press References" />

      <Layout>
        <div className="flex flex-col p-5">
          {edges.map(({ node }) => {
            const { id, title, description, publishDate, url, featured } = node
            const date = dayjs(publishDate).format('LL')

            return (
              <article key={id} className="flex flex-col mb-5 w-full text-center my-0 mx-auto max-w-xs md:max-w-xl">
                <div className="text-sm dark:text-gray-500 text-gray-400 subpixel-antialiased">
                  {date}
                  {featured && <span className="bg-red-400 text-white pl-1 pr-1 text-xs ml-2">new</span>}
                </div>

                <div className="text-base subpixel-antialiased my-0 mx-auto max-w-xs md:max-w-xl">
                  <span className="mr-4 dark:text-gray-300 text-gray-600 font-bold">{title}</span>
                  <span className="dark:text-gray-400 text-gray-400">{description}</span>
                </div>
                <a href={url} className="text-blue-700 truncate md:overflow-clip" target="_blank" rel="noreferrer">
                  {url}
                </a>
              </article>
            )
          })}
        </div>
      </Layout>
    </>
  )
}

export default Press
